
  import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
    },
  })
  export default class FilterSearch extends Vue {
    @Prop({ default: () => "" }) value!: string;
    @Prop({ default: () => "Zoeken..." }) placeholder!: string;
    @Prop({ default: () => false }) autoFocus?: boolean;
    @Prop({ default: () => [] }) results!: object[];
    @Prop({ default: () => false }) loading!: boolean;

    localValue = "";

    show = false;

    selectedIndex = -1;

    @Emit("input")
    handleInput() {
      return this.localValue;
    }

    handleClick() {
      this.show = this.results.length > 0 && !this.valueIsInResults && this.localValue !== "";
    }

    clearInput() {
      this.localValue = "";
      this.$emit("input", "");
      this.show = false;
      this.$nextTick(() => {
        if (this.$refs.input) {
          (this.$refs.input as HTMLInputElement).focus();
        }
      });
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: string) {
      this.selectedIndex = -1;
      this.localValue = newValue;
    }

    @Watch("results", { immediate: true })
    resultsChanged(results: object[]) {
      this.show = results.length > 0 && !this.valueIsInResults && this.localValue !== "";
    }

    handleClose() {
      this.show = false;
    }

    get valueIsInResults() {
      return this.results.some((result: any) => result.title === this.value);
    }

    get showDropdown() {
      return this.show === true || this.loading === true ? true : false;
    }

    async handleScroll() {
      // TODO: implement infinite scroll
    }

    handleKey(e: KeyboardEvent) {
      if (e.key === "ArrowDown") {
        if (this.selectedIndex === this.results.length - 1) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex += 1;
        }
      }
      if (e.key === "ArrowUp") {
        if (this.selectedIndex <= 0) {
          this.selectedIndex = this.results.length - 1;
        } else {
          this.selectedIndex -= 1;
        }
      }
      if (e.key === "Enter") {
        if (this.selectedIndex === -1) {
          this.$emit("search", this.localValue);
          this.handleClose();
        } else {
          const item = this.results[this.selectedIndex];
          this.$emit("selected", item);
        }
        this.handleClose();
      }
    }

    @Watch("valueIsInResults")
    valueIsInResultsChanged(newValue: boolean) {
      if (newValue === true) {
        this.handleClose();
      }
    }
  }
